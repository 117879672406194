* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .profile{
    margin-top: 2.2em;
    position: relative;
  }

  .company-logo-circular {
    width: 50px;
    height: 50px;
    background-size: cover;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
  }

.center {
  justify-content: center;
  text-align: center;
  margin-bottom: 20;
}

.center-horizontaly {
  margin-left: auto;
  margin-right: auto;
}

.center-vertically {
  margin-top: auto;
  margin-bottom: auto;
}

@media (max-width: 1024px) {
  .main-container {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    max-width: 100%;
    margin-left: 6%;
    margin-right: 6%;
    border-radius: 50px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

@media (min-width: 1024px) {
  .main-container {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    max-width: 100%;
    margin-left: 30%;
    margin-right: 30%;
    border-radius: 50px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

.footer-container {
  max-width: 100%;
  margin-left: 10%;
  margin-right: 10%;
}

.container {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    background-color: #E9EBEC;
    border-radius: 50px;
    align-items: center;
    justify-content: center;
}

.child-container {
    margin-left: 50px;
    margin-top: 20px;
    max-width: 70%;
}

.child-container-header {
    justify-content: center;
}

.flex-wrapper {
    display: flex;
}

.left {
    flex: 0 0 65%;
}

.right {
    flex: 1;
}

.leftGroup {
    flex: auto;
}

.margin-left-15 {
  margin-left: 15px;
}

.margin-left-5 {
  margin-left: 5px;
}

.padding-left-5 {
  padding-left: 5px;
}

.padding-left-15 {
  padding-left: 15px;
}

.padding-top-15 {
  padding-top: 15px;
}

.padding-bottom-15 {
  padding-bottom: 15px;
}

.padding-bottom-5 {
  padding-bottom: 5px;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-top-15 {
  margin-top: 15px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-right-30 {
  margin-right: 30px;
}

.padding-top-30 {
  padding-top: 30px;
}

.padding-left-70 {
  padding-left: 70px;
}
.padding-right-30 {
  padding-right: 30px;
}

.profile-picture {
  border: 2px solid #B764FF;
  border-radius: 50%;
}

.card {
  width: 200%;
  height: auto;
  border-radius: 5%;
}

.company-info-header {
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 80%;
  margin-right: 15%;
  margin-left: 15%;
}

.company-info-section {
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 70%;
  margin-left: 15%;
}

.quote {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  margin-left: 25%;
  margin-right: 25%;
}

.float-right{
  float:right;
}

.anti-center {
  justify-content: start;
  text-align: left;
}

.footer-heading {
  font-size: 18px;
  color: black;
}

.footer-heading-2 {
  font-size: 20px;
  color: #696969;
} 

.footer-description {
  font-size: 13px;
  color:  #696969;
}

.description-heading {
  font-size: 11px;
  color: black;
}

.description {
  font-size: 10px;
  color:  #696969;
}

.card-holder-name{
  font-size: 18px;
  color: black;
}

.card-holder-description{
  font-size: 11px;
  color: #696969;
}

.work-heading{
  font-size: 18px;
  color: black;
}

.work-heading-description{
  font-size: 12px;
  color: #696969;
}
.btn {
  border: none;
 
}
.btn:focus {
  outline: none;
  box-shadow: none;
}

.curser-pointer {
  cursor: pointer;
}

.tag{
  font-size: 8px;
  background-color: #8B8EEF;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 3px;
}

.font-size-13 {
  font-size: 13px;
}

.font-size-16 {
  font-size: 16px;
}


.row {
  display: -ms-flexbox;
  display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}


.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.left-arrow-disabled { 
  width: 15px; 
  height: 15px;
  cursor: pointer;
  background: url("../../images/weblink-images/left-arrow-disable.svg") no-repeat center center;
}

.right-arrow-disabled { 
  width: 15px; 
  height: 15px;
  cursor: pointer;

  background: url("../../images/weblink-images/right-arrow-disable.svg") no-repeat center center;
} 

.left-arrow-enabled { 
  width: 15px; 
  height: 15px;
  cursor: pointer; 
  background: url("../../images/weblink-images/left-arrow-enable.svg") no-repeat center center;
}

.right-arrow-enabled { 
  width: 15px; 
  height: 15px;
  cursor: pointer;
  background: url("../../images/weblink-images/right-arrow-enable.svg") no-repeat center center;

} 

.slide-arrow {
  margin-top: auto;
  margin-bottom: auto;
}

.slide-shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.slide-corner{
  border-radius: 3%;
}

.box-shadow{
  -webkit-box-shadow: 0px 0px 20px #ccc;
  -moz-box-shadow: 0px 0px 20px #ccc;
  box-shadow: 0px 0px 20px #ccc;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
    position: absolute;
    z-index: 999;
}
div.ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: .05em .01em .5em rgba(0,0,0,.2);
    background: white;
    width: 631px;
}
div.ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
}
div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
}


  
