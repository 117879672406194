body, html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Roboto, sans-serif;
    width: 100%;
    background: #fafafa;
    color: #757575;
}

*, *:before, *:after {
    box-sizing: inherit;
}

.container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.title {
    display: block;
    margin: 0 0 20px;
    font-size: 24px;
    text-transform: uppercase;
}

.radio {
    display: inline-block;
    position: relative;
    margin: 0 0 10px;
    font-size: 16px;
    line-height: 24px;
}
.radioInput {
    position: absolute;
    top: 4px;
    left: 0;
    width: 36px;
    height: 20px;
    opacity: 0;
    z-index: 0;
}
.radioLabel {
    display: block;
    padding: 0 0 0 24px;
    cursor: pointer;
    font-size: 17px !important;
    font-weight: 400 !important;
}
.radioLabel:before {
    content: "";
    position: absolute;
    top: 4px;
    left: 0;
    width: 16px;
    height: 16px;
    background-color: #d7d7d7;
    border: 0px solid rgba(0, 0, 0, 0.54);
    border-radius: 14px;
    z-index: 1;
    transition: border-color 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}
.radioLabel:after {
    content: "";
    position: absolute;
    top: 8px;
    left: 4px;
    width: 8px;
    height: 8px;
    background-color: #000;
    border-radius: 50%;
    z-index: 2;
    transform: scale(0, 0);
    transition: transform 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}
.radioInput:checked + .radioLabel:before {
    border-color: #3f51b5;
}
.radioInput:checked + .radioLabel:after {
    transform: scale(1, 1);
} 